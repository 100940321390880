import React, { useState, useEffect } from "react";
import { 
  Box,
  Stack,
  Typography, 
} from "@mui/material";
import { fetchLeaderboard } from "../utils/services";
import { Entry } from "../utils/types";

type InputProps = {
  refresh: number;
}

/**
 * Comments - Handles the actual rsvps
 */
const Comments = ({ refresh }: InputProps) => {
  const [leaderboard, setLeaderboard] = useState<Entry[]>([]);

  useEffect(() => {
    fetchLeaderboard()
      .then((entries) => {
        // Order by score descending
        setLeaderboard(entries.sort((a, b) => b.score - a.score));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [refresh]);

  const renderLeaderboard = () => {
    return (
      <Stack spacing={2}>
        {leaderboard.map((entry, index) => (
          <Stack 
            direction={{ xs: 'column', sm: 'column', md: 'row' , lg: 'row'}}
            spacing={{ xs: 1, sm: 2, md: 6, lg: 6 }}
            alignItems="flex-start"
            key={`leaderboard-${index}`}
          >
            <Typography color="#d4793b" fontSize={32} fontWeight={"bold"}>
              #{index + 1}
            </Typography>
            <Stack spacing={1}>
              <Stack 
                direction={{ xs: 'column', sm: 'column', md: 'row' , lg: 'row'}}
                spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
                alignItems={{ xs: 'stretch', sm: 'stretch', md: 'center' , lg: 'center'}}
              >
                {entry.icon ? (
                  <img src={`${process.env.PUBLIC_URL}/icons/${entry.icon}`} alt={entry.name} width={40} />
                ) : (
                  <img src={`${process.env.PUBLIC_URL}/icons/ico-a_old_201-y.gif`} alt={entry.name} width={40} />
                )}
                <Typography color="#d4793b" fontSize={14} fontWeight={"bold"}>
                  {entry.name} 
                </Typography>
                <Typography color="gray" fontSize={12}>
                  SCORE : {entry.score} 
                </Typography>
                <Typography color="gray" fontSize={12}>
                  TRIES : {entry.tries} 
                </Typography>
                <Typography color="gray" fontSize={12}>
                  TEAM : <b>{entry.team === 0 ? "CONNIE" : "MIKE"}</b>
                </Typography>
              </Stack>
              <Typography color="black" fontFamily="DM Mono !important" fontSize={18}>
                {entry.comments}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    );
  }

  const renderEmpty = () => {
    return (
      <Stack spacing={2}>
        <Stack 
          direction={{ xs: 'column', sm: 'column', md: 'row' , lg: 'row'}}
          spacing={{ xs: 1, sm: 2, md: 6, lg: 6 }}
          alignItems="flex-start"
        >
          <Typography color="#d4793b" fontSize={32} fontWeight={"bold"}>
            #{1}
          </Typography>
          <Stack spacing={1}>
            <Stack 
              direction={{ xs: 'column', sm: 'column', md: 'row' , lg: 'row'}}
              spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}
              alignItems={{ xs: 'stretch', sm: 'stretch', md: 'center' , lg: 'center'}}
            >
              <img src={`${process.env.PUBLIC_URL}/icons/ico-a_old_201-y.gif`} width={40} />
              <Typography color="#d4793b" fontSize={14} fontWeight={"bold"}>
                Player42
              </Typography>
              <Typography color="gray" fontSize={12}>
                SCORE : 42
              </Typography>
              <Typography color="gray" fontSize={12}>
                TRIES : 1
              </Typography>
              <Typography color="gray" fontSize={12}>
                TEAM : <b>CONNIE</b>
              </Typography>
            </Stack>
            <Typography color="black" fontFamily="DM Mono !important" fontSize={18}>
              No entries yet! Submit your score to be the first!
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" color="black" fontWeight={"bold"}>
          leaderboard
        </Typography>
      </Box>
      {leaderboard.length > 0 ? renderLeaderboard() : renderEmpty()}
    </Box>
  );
}

export default Comments;