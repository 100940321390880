import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const Information = () => {
  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        border: '2px solid rgb(233, 196, 106)', // Adds a 1px solid black border
        borderRadius: '4px', // Optional: Adds rounded corners
      }}
    >
      <Grid container columns={12}>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
            status
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          engaged
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          launch party
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          los angeles
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          release date
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          june 13, 2025
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          rating
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          ⭐⭐⭐⭐⭐ (65)
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          author
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          wu x liu
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          genre
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          rpg, friendly satire, romance
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          made with
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          ❤️
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          secret ending
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          ✔
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={6} sx={{ color: "rgb(233, 196, 106)", fontWeight: "bold" }}>
          tags
        </Grid>
        <Grid item lg={10} md={8} sm={6} xs={6}>
          immersive, party quest, wedding invitation
        </Grid>
      </Grid>
    </Box>
  );
}

export default Information;