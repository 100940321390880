import { axiosInstance } from "./axios";
import type { Entry, EntryCreate, EntryUpdate } from "./types";

/**
 * Fetch leaderboard entries
 * @returns Array of entries
 */
export const fetchLeaderboard = async (): Promise<Entry[]> => {
  return axiosInstance
  .get('/api/leaderboard', { params: { skip: 0, limit: 100 }})
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchLeaderboard`", err);
  });
};

/**
 * Fetch an entry by name
 */
export const fetchRsvp = async (name: string): Promise<Entry | undefined> => {
  return axiosInstance.get(`/api/leaderboard/${name}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchEntryByName`", err);
  });
};

/**
 * Record an RSVP
 * @param entry 
 * @returns 
 */
export const recordRSVP = async (body: EntryCreate): Promise<Entry> => {
  return axiosInstance.post('/api/rsvp', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `recordRSVP`", err);
  });
};

/**
 * Update the score of an entry
 * @param entry 
 * @returns 
 */
export const updateScore = async (body: EntryUpdate): Promise<Entry> => {
  return axiosInstance.post('/api/update', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateScore`", err);
  });
};