import React, { useState, useEffect } from "react";
import { 
  Box,
  Grid,
  Button,
  Stack,
  Typography, 
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import toast from 'react-hot-toast';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { recordRSVP, fetchRsvp, updateScore } from "../utils/services";
import { EntryCreate, EntryUpdate, Entry } from "../utils/types";

type GuestIcon = {
  name: string;
  icon: string;
}

const guests: GuestIcon[] = [
  { name: "Mike Wu", icon: "ico-a_old_mike.gif" },
  { name: "Connie Liu", icon: "ico-a_old_connie.gif" },
  { name: "Robbie Shaw", icon: "ico-a_old_robbie.gif" },
  { name: "Parker Zhao", icon: "ico-a_old_parker.gif" },
  { name: "Laura Watterson", icon: "ico-a_old_laura.gif" },
  { name: "Michael Gregory", icon: "ico-a_old_michael.gif" },
  { name: "Steven Kang", icon: "ico-a_old_steven.gif" },
  { name: "Don Woodson", icon: "ico-a_old_don.gif" },
  { name: "Wei Feng", icon: 'ico-a_old_069.gif' },
  { name: "Nick Wu", icon: 'ico-a_old_095.gif' },
  { name: "Bing Han", icon: 'ico-a_old_124.gif' },
  { name: "Zhengyu Liu", icon: 'ico-a_old_143.gif' },
  { name: "Stanley Liu", icon: 'ico-a_old_066.gif' },
  { name: "Kat Wang", icon: 'ico-a_old_024.gif' },
  { name: "Tracey Liu", icon: 'ico-a_old_060.gif' },
  { name: "Brian Kernan", icon: 'ico-a_old_113.gif' },
  { name: "Connor Kernan", icon: 'ico-a_old_135.gif' },
  { name: "Christopher Kernan", icon: 'ico-a_old_011.gif' },
  { name: "Amanda Greco", icon: 'ico-a_old_084.gif' },
  { name: "Mengli Shi", icon: 'ico-a_old_109.gif' },
  { name: "Anna Englezos", icon: 'ico-a_old_012.gif' },
  { name: "Anthony Kim", icon: 'ico-a_old_098.gif' },
  { name: "Viet Nguyen", icon: 'ico-a_old_137.gif' },
  { name: "Nabig Chaudhry", icon: 'ico-a_old_092.gif' },
  { name: "Hillary Do", icon: 'ico-a_old_148.gif' },
  { name: "Alejandro Ashworth", icon: 'ico-a_old_043.gif' },
  { name: "Priya Garg", icon: 'ico-a_old_108.gif' },
  { name: "Jenny Wang", icon: 'ico-a_old_050.gif' },
  { name: "Rachel Lathe", icon: 'ico-a_old_005.gif' },
  { name: "Brian Rivas", icon: 'ico-a_old_009.gif' },
  { name: "Rue Park", icon: 'ico-a_old_026.gif' },
  { name: "Edward Li", icon: 'ico-a_old_022.gif' },
  { name: "Orly Michaeli", icon: 'ico-a_old_032.gif' },
  { name: "Ben Goldwater", icon: 'ico-a_old_004.gif' },
  { name: "Jodie Chen", icon: 'ico-a_old_094.gif' },
  { name: "Jonathan Wu", icon: 'ico-a_old_130.gif' },
  { name: "Galen Krulce", icon: 'ico-a_old_081.gif' },
  { name: "Kelly Lampotang", icon: 'ico-a_old_134.gif' },
  { name: "Ian Watterson", icon: 'ico-a_old_058.gif' },
  { name: "Yvonne Ploder", icon: 'ico-a_old_040.gif' },
  { name: "Diane Kim", icon: 'ico-a_old_120.gif' },
  { name: "Kelly Liu", icon: 'ico-a_old_041.gif' },
  { name: "Justin Yuan", icon: 'ico-a_old_129.gif' },
  { name: "Leigh Monistere", icon: 'ico-a_old_019.gif' },
  { name: "Sammy Monistere", icon: 'ico-a_old_136.gif' },
  { name: "Sarah Lee", icon: 'ico-a_old_072.gif' },
  { name: "Young Kang", icon: 'ico-a_old_061.gif' },
  { name: "Kyle Jablon", icon: 'ico-a_old_039.gif' },
  { name: "Kevin Li", icon: 'ico-a_old_001.gif' },
  { name: "Lily Liu", icon: 'ico-a_old_003.gif' },
  { name: "Joey Barnett", icon: 'ico-a_old_035.gif' },
  { name: "Alex Villafuerte", icon: 'ico-a_old_068.gif' },
  { name: "Evan Tey", icon: 'ico-a_old_147.gif' },
  { name: "Kristy Choi", icon: 'ico-a_old_122.gif' },
  { name: "Gary Cheng", icon: 'ico-a_old_089.gif' },
  { name: "Jonathan Chang", icon: 'ico-a_old_114.gif' },
  { name: "Carleen Liu", icon: 'ico-a_old_048.gif' },
  { name: "Anusha Sharma", icon: 'ico-a_old_010.gif' },
  { name: "Michael Cerqueira", icon: 'ico-a_old_051.gif' },
  { name: "Kara Park", icon: 'ico-a_old_093.gif' },
  { name: "Sun Tavares", icon: 'ico-a_old_121.gif' },
  { name: "Lia Parker-Belfer", icon: 'ico-a_old_104.gif' },
  { name: "Alex Mitko", icon: 'ico-a_old_132.gif' },
  { name: "Joy Shen-Bloomberg", icon: 'ico-a_old_006.gif' },
  { name: "Brent Bloomberg", icon: 'ico-a_old_063.gif' },
  { name: "Rebecca Su", icon: 'ico-a_old_096.gif' },
].sort((a, b) => a.name.localeCompare(b.name));

// unused
// 'ico-a_old_008.gif'
// 'ico-a_old_054.gif'
// 'ico-a_old_002.gif'
// 'ico-a_old_046.gif'
// 'ico-a_old_023.gif'
// 'ico-a_old_074.gif'
// 'ico-a_old_007.gif'
// 'ico-a_old_025.gif'

const getPokemonFromName = (name: string): string => {
  const guest = guests.find(guest => guest.name === name);
  if (!guest) {
    return 'ico-a_old_201-y.gif'
  } 
  return guest.icon;
}

type InputProps = {
  score: number;
  team: number;
  onSubmit: () => void;
}

const RsvpForm = ({ score, team, onSubmit }: InputProps) => {
  const [guestName, setGuestName] = useState<string>("");
  const [dietaryRestrictions, setDietaryRestrictions] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  // Whether the user has already submitted their RSVP
  const [isReplay, setIsReplay] = useState<boolean>(false);
  const [entry, setEntry] = useState<Entry>();

  useEffect(() => {
    if (guestName.length > 0) {
      fetchRsvp(guestName)
      .then((res) => {
        if (res) {
          setIsReplay(true);
        } else {
          setIsReplay(false);
        }
        setEntry(res);
      })
      .catch((err) => {
        console.error("Error in `fetchRsvp`", err);
      });
    }
  }, [guestName]);

  return (
    <Box 
      justifyContent="center"
      style={{ 
        backgroundColor: "#e9c46a",
        width: 600,
        height: 600,
        borderRadius: 4,
        margin: "0 auto",
      }}
      sx={{
        px: 4,
        py: 3,
      }}
    >
      <Typography variant="h6" gutterBottom>
        <center><b>CONGRATS! YOU'RE A WULIUMON MASTER!</b> </center>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <center>Come celebrate with us at the WuLiu ceremony: it will not be Pokemon themed don't worry.</center>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <center><b>Friday, June 13, 2025 @ <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/9vvb89fQL8DudHuy5">Millwick</a> in LA (5PM-1AM)</b></center>
      </Typography>
      <Grid container columns={12} gap={1}>
        <Grid item xs={isReplay ? 12 : 5.5}>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth> 
              <Typography variant="h6" fontSize={14}>
                name? (type to search)
              </Typography>
              <Select
                id="name-select"
                value={guestName}
                fullWidth
                onChange={(e: SelectChangeEvent) => setGuestName(e.target.value as string)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,  // Set your desired max height here
                    },
                  },
                }}
              >
                {guests.map(guest => (
                  <MenuItem 
                    key={guest.name}
                    value={guest.name}
                    sx={{ fontFamily: 'DM Mono'}}
                  >
                    <Stack direction="row" spacing={2}>
                      <img src={`${process.env.PUBLIC_URL}/icons/${guest.icon}`} alt={guest.name} />
                      <Typography sx={{ fontFamily: 'DM Mono !important'}}>{guest.name}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {!isReplay && (
          <Grid item xs={6}>
            <Box sx={{ mt: 2, pl: 2 }}>
              <FormControl fullWidth> 
                <Typography variant="h6" fontSize={14}>
                  email? (calendar invite)
                </Typography>
                <TextField 
                  fullWidth 
                  sx={{
                    '& .MuiInputBase-input': {
                      fontFamily: 'DM Mono',  // Change the font family
                      fontSize: 16,
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: 'DM Mono',  // Change the label font family
                    },
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Box>
          </Grid>
        )}
        {isReplay && entry && (
          <Grid item xs={12}>
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" gutterBottom>
                <b>Thanks for playing again!</b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Would you like to update your score?
              </Typography>
            </Box>
            <Box sx={{
              border: "2px solid black",
              backgroundColor: "gray",
              borderRadius: 2,
              padding: 1,
              mb: 2,
            }}>
              {score > entry!.score ? (
                <Typography variant="h5" gutterBottom fontSize={40} textAlign="center">
                  🎉
                </Typography>
              ) : (
                <Typography variant="h5" gutterBottom fontSize={40} textAlign="center">
                  🤣
                </Typography>
              )}
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5" gutterBottom fontSize={16} textAlign="center" color="white">
                  OLD SCORE: {entry!.score} PTS
                </Typography>
              </Box>
              <Typography variant="h5" gutterBottom fontSize={16} textAlign="center" color="white">
                NEW SCORE: {score} PTS
              </Typography>
            </Box>
          </Grid>
        )}
        {!isReplay && (
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <FormControl fullWidth> 
                <Typography variant="h6" fontSize={14}>
                  dinner will be family style chinese food. do you have any dietary restrictions or strong dislikes?
                </Typography>
                <TextField 
                  fullWidth 
                  sx={{
                    '& .MuiInputBase-input': {
                      fontFamily: 'DM Mono',  // Change the font family
                      fontSize: 16,
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: 'DM Mono',  // Change the label font family
                    },
                  }}
                  onChange={(e) => setDietaryRestrictions(e.target.value)}
                />
              </FormControl>
            </Box>
          </Grid>
        )}
        {!isReplay && (
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <FormControl fullWidth> 
                <Typography variant="h6" fontSize={14}>
                  drop a mandatory note for us on our leaderboard!
                </Typography>
                <TextField 
                  multiline 
                  rows={3} 
                  fullWidth 
                  sx={{
                    '& .MuiInputBase-input': {
                      fontFamily: 'DM Mono',  // Change the font family
                      fontSize: 16,
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: 'DM Mono',  // Change the label font family
                    },
                  }}
                  onChange={(e) => setComment(e.target.value)}
                />
              </FormControl>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Button 
            style={{
              borderRadius: 4,
              backgroundColor: "white",
            }}
            onClick={() => {
              if (isReplay) {
                const body: EntryUpdate = {
                  name: guestName,
                  score: score,
                  team: team,
                  tries: entry!.tries + 1,
                };
                const toastId = toast.loading("recording score...");
                // Make a POST request to record new score
                updateScore(body)
                .then(() => {
                  toast.dismiss(toastId);
                  toast.success("Score updated!");
                  onSubmit();
                })
                .catch((err) => {
                  toast.dismiss(toastId);
                  toast.error("Error in recording score");
                });
              } else {
                if (guestName === "") {
                  toast.error("Please pick your name from the dropdown!");
                } else if (comment === "") {
                  toast.error("Add a comment! You can do better than that...");
                } else if (email === "") {
                  toast.error("Please add an email so we can send you a calendar invite!");
                } else {
                  const body: EntryCreate = {
                    name: guestName,
                    email: email,
                    score: score,
                    icon: getPokemonFromName(guestName),
                    dietary_restrictions: dietaryRestrictions,
                    comments: comment,
                    team: team,
                    tries: 1,
                  };
                  const toastId = toast.loading("recording RSVP...");
                  // Make a POST request to record the RSVP
                  recordRSVP(body)
                  .then(() => {
                    toast.dismiss(toastId);
                    toast.success("RSVP saved!");
                    onSubmit();
                  })
                  .catch((err) => {
                    toast.dismiss(toastId);
                    toast.error("Error in recording RSVP");
                  });
                }
              }
            }}
          >
            <Typography style={{ color: "black" }}>
              {isReplay ? "Update" : "Submit"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RsvpForm;