import React from 'react';
import { Box, Typography } from '@mui/material';

const Description = () => {
  return (
    <Box>
      <Typography variant="h6" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        you can't just walk in here and get a wedding invite... you have to battle for it.
      </Typography>
      <Typography variant="h6" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        if you're on a phone, go to a laptop. this ain't mobile friendly.
      </Typography>
      <Typography variant="h6" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        use your arrow keys to navigate. press "enter" to select. 
      </Typography>
      <Typography variant="h6" component="ul" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        <li>
          <Typography variant="h6" style={{ color: 'black' }}>
            art and words by <a href="https://conniekliu.com/">connie</a>
          </Typography>
        </li>
        <li>
          <Typography variant="h6" style={{ color: 'black' }}>
            code by <a href="https://mikehwu.com/">mike</a>
          </Typography>
        </li>
        <li>
          <Typography variant="h6" style={{ color: 'black' }}>
            everything else by nintendo. please don't sue us
          </Typography>
        </li>
      </Typography>
      <Typography variant="h6" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        playtime: 5-10 minutes
      </Typography>
      <Typography variant="h6" style={{ color: 'black', lineHeight: 1.4 }} sx={{ py: 1.2 }}>
        disclaimer: any resemblance to real persons or other real-life entities is <u>purely</u> coincidental. all characters and other entities appearing in this work <u>are</u> fictitious
      </Typography>
    </Box>
  )
}

export default Description;